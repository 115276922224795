<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">The proper solvent choice is crucial for effective recrystallization.</p>
      <p class="mb-2">What are the features of a good recrystallization solvent?</p>

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LB_A8Q11',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {
          text: 'The solvent should dissolve a moderate quantity of the target substance near its boiling point but only a small quantity near $0^{\\circ}\\text{C.}$',
          value: 'dissolve',
        },
        {text: 'The solvent should not react with the target substance.', value: 'shouldNotReact'},
        {
          text: 'The solvent should be easily removed from the purified product.',
          value: 'easilyRemoved',
        },
        {
          text: 'The crude compound should be soluble in this solvent at room temperature.',
          value: 'solubleRoomTemp',
        },
        {text: 'The solvent should have a high boiling point.', value: 'highBoilingPoint'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
